.nav-pills .nav-link.active {
  border-bottom: 2px solid #6750a4;
  background-color: #f9f8f8;
  border-radius: 0%;
}
.new-meeting {
  background-color: #6750a4;
  border: #6750a4;
  border-radius: 50px;
}
.new-meeting:hover {
  background-color: #6750a4;
  border: #6750a4;
}
.btn:first-child:active {
  background-color: #6750a4;
  color: #fff;
  border: #6750a4;
}
.new-meet-modal-title {
  font-weight: bold;
  font-size: x-large;
}
.modal-content {
  border-radius: 30px !important;
}
.modal-header {
  border-bottom: 0 !important;
}
